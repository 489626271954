/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import { ProjectPage, Loader } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  PrunePortfolioModal,
  AddPatentsToPortfolioModal,
  PortfolioTable,
  UploadFilesToPortfolioModal,
  AddReferencesToPortfolioModal,
} from "./components";

/**
 * @description Portfolio page for project
 */
const PortfolioPage: React.FC = () => {
  const { currentPortfolioDetails } = useProjectStore();
  const { isLoading } = useAppStateStore();

  // Page title
  const pageName = currentPortfolioDetails.name
    ? `${currentPortfolioDetails.name} - Portfolio - &AI`
    : "Portfolio - &AI";

  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showPruneModal, setShowPruneModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);

  return (
    <ProjectPage pageName={pageName}>
      <div className="p-2">
        <div className="flex justify-end mb-2">
          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">Add References</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => setShowAddReferencesModal(true)}>
                Add Published Patents/Applications
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setShowUpdateModal(true)}>
                Add Other Documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu> */}
          <div className="flex gap-2">
            {currentPortfolioDetails.projects?.length > 1 && (
              <Button variant="outline" onClick={() => setShowPruneModal(true)}>
                Prune Subjects
              </Button>
            )}
            {currentPortfolioDetails.projects?.length < 100 && (
              <Button onClick={() => setShowAddNewModal(true)}>
                Add Subjects
              </Button>
            )}
          </div>
        </div>
        {isLoading ? <Loader message="Loading..." /> : <PortfolioTable />}
      </div>
      <PrunePortfolioModal
        open={showPruneModal}
        handleClose={() => setShowPruneModal(false)}
      />
      <AddPatentsToPortfolioModal
        open={showAddNewModal}
        handleClose={() => setShowAddNewModal(false)}
      />
      <AddReferencesToPortfolioModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
      />
      <UploadFilesToPortfolioModal
        open={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
      />
    </ProjectPage>
  );
};

export default PortfolioPage;
