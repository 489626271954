import React, { useState } from "react";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { Button } from "../ui/button";
import { X, ChevronDown, ChevronUp, CheckCircle, AlertCircle } from "lucide-react";
import { Badge } from "../ui/badge";
import { Spinner } from "../ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { capitalizeFirstLetter } from "@/utils/dataUtils";

interface ReferenceLoadingAlertProps {
  projectReferenceLoadingMessage: any; // Replace 'any' with the correct type
  removeReferenceLoadingMessage: (projectId: string) => void;
}

const ReferenceLoadingAlert: React.FC<ReferenceLoadingAlertProps> = ({
  projectReferenceLoadingMessage,
  removeReferenceLoadingMessage,
}) => {
  const [showReferenceDetails, setShowReferenceDetails] = useState(false);

  const dedupedMessages: any[] = Array.from(
    new Map(
      projectReferenceLoadingMessage.messages.map((message) => [message.name, message])
    ).values()
  );

  const totalCount = dedupedMessages.length;
  const successCount = dedupedMessages.filter((m) => m.status === "success").length;
  const errorCount = dedupedMessages.filter((m) => m.status === "error").length;
  const loadingCount = totalCount - successCount - errorCount;

  const getStatusIcon = () => {
    if (loadingCount > 0) {
      return <Spinner className="mr-2 h-4 w-4" />;
    } else if (errorCount > 0) {
      return <AlertCircle className="mr-2 h-4 w-4 text-red-500" />;
    } else {
      return <CheckCircle className="mr-2 h-4 w-4 text-blue-500" />;
    }
  };

  return (
    <Alert variant="default" className="flex items-center pr-8 relative">
      <div>
        <div className="flex items-center">
          <div className="flex items-center justify-between gap-2">
            {getStatusIcon()}
            <AlertTitle>
              {(() => {
                if (loadingCount > 0) {
                  return `Adding ${loadingCount} ${loadingCount === 1 ? "reference" : "references"} to ${projectReferenceLoadingMessage.project_name}`;
                } else if (errorCount > 0) {
                  return `Added ${successCount} ${successCount === 1 ? "reference" : "references"} to ${projectReferenceLoadingMessage.project_name}. ${errorCount} failed`;
                } else {
                  return `Added ${successCount} ${successCount === 1 ? "reference" : "references"} to ${projectReferenceLoadingMessage.project_name}`;
                }
              })()}{" "}
            </AlertTitle>
            <div className="flex items-center">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => setShowReferenceDetails(!showReferenceDetails)}
                      className="mr-2"
                    >
                      {showReferenceDetails ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {showReferenceDetails ? "Hide Details" : "View Details"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() =>
                        removeReferenceLoadingMessage(
                          projectReferenceLoadingMessage.project_id
                        )
                      }
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Dismiss</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>
        {showReferenceDetails && (
          <div className="mt-2 space-y-2">
            {dedupedMessages.map((message) => (
              <div key={message.name} className="flex flex-col">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {message.status === "success" ? (
                      <CheckCircle className="mr-3 flex-shrink-0 h-4 w-4 text-blue-500" />
                    ) : message.status === "error" ? (
                      <AlertCircle className="mr-3 flex-shrink-0 h-4 w-4 text-red-500" />
                    ) : (
                      <Spinner className="mr-3 flex-shrink-0 h-4 w-4" />
                    )}
                    <span>{message.name}</span>
                  </div>
                  <Badge variant="outline">
                    {capitalizeFirstLetter(message.status)}
                  </Badge>
                </div>
                <p className="ml-8 text-sm text-destructive">{message.errorMessage}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </Alert>
  );
};

export default ReferenceLoadingAlert;
