/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "@propelauth/react";
import { isMobile } from "react-device-detect";
import HomePage from "./features/home/HomePage";
import ElementChartsPage from "./features/project/charts/ElementChartsPage";
import CreateProjectPage from "./features/project/create/CreateProjectPage";
import CreateApplicationProjectPage from "./features/project/create/CreateApplicationProjectPage";
import CreatePortfolioProjectPage from "./features/project/create/CreatePortfolioProjectPage";
import SuperAdminPage from "./features/admin/superadmin/SuperAdminPage";
import AdminPage from "./features/admin/admin/AdminPage";
import ProjectChatPage from "./features/explore/chat/ProjectChatPage";
import ExploreChatPage from "./features/explore/chat/ExploreChatPage";
import SubjectPage from "./features/project/documents/SubjectPage";
import ProjectSearchPage from "./features/explore/search/ProjectSearchPage";
import PageNotFound from "./features/PageNotFound";
import LogInPage from "./features/auth/LogInPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import ExploreSearchPage from "./features/explore/search/ExploreSearchPage";
import ReferencesPage from "./features/project/documents/ReferencesPage";
import UnauthorizedPage from "./features/project/UnauthorizedPage";
import ContextPage from "./features/project/documents/ContextPage";
import ExplorePage from "./features/explore/ExplorePage";
import PortfolioPage from "./features/project/portfolio/PortfolioPage";
import { H4, Paragraph } from "./components/ui/typography";
import { ThemeProvider } from "@/styled/ThemeProvider";
import SuperAdminOrganizationPage from "./features/admin/superadmin/SuperAdminOrganizationPage";
import AdminUsersPage from "./features/admin/admin/AdminUsersPage";
import AdminBillingPage from "./features/admin/admin/AdminBillingPage";
import SuperAdminOrganizationDetailsPage from "./features/admin/superadmin/SuperAdminOrganizationDetailsPage";
import SuperAdminBillingPage from "./features/admin/superadmin/SuperAdminBillingPage";
import PortfolioReferencesPage from "./features/project/portfolio/PortfolioReferencesPage";
const AppContent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<LogInPage />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="*" element={<Navigate to="/signin" />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/billing" element={<AdminBillingPage />} />
          <Route path="/superadmin" element={<SuperAdminPage />} />
          <Route
            path="/superadmin/billing"
            element={<SuperAdminBillingPage />}
          />
          <Route
            path="/superadmin/organizations"
            element={<SuperAdminOrganizationPage />}
          />
          <Route
            path="/superadmin/organizations/:organizationId"
            element={<SuperAdminOrganizationDetailsPage />}
          />
          <Route path="/explore/search" element={<ExploreSearchPage />} />
          <Route path="/explore/chat" element={<ExploreChatPage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route
            path="/project/:projectId/charts"
            element={<ElementChartsPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/charts"
            element={<ElementChartsPage />}
          />
          <Route
            path="/project/:projectId/search"
            element={<ProjectSearchPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/search"
            element={<ProjectSearchPage />}
          />
          <Route path="/project/:projectId/subject" element={<SubjectPage />} />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/subject"
            element={<SubjectPage />}
          />
          <Route
            path="/project/:projectId/references"
            element={<ReferencesPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/references"
            element={<ReferencesPage />}
          />
          <Route
            path="/portfolio/:portfolioId/subjects"
            element={<PortfolioPage />}
          />
          <Route
            path="/portfolio/:portfolioId/references"
            element={<PortfolioReferencesPage />}
          />
          <Route path="/create-project" element={<CreateProjectPage />} />
          <Route
            path="/create-application-project"
            element={<CreateApplicationProjectPage />}
          />
          <Route
            path="/create-portfolio-project"
            element={<CreatePortfolioProjectPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/chat"
            element={<ProjectChatPage />}
          />
          <Route
            path="/project/:projectId/chat"
            element={<ProjectChatPage />}
          />

          <Route
            path="/project/:projectId/unauthorized"
            element={<UnauthorizedPage />}
          />
          <Route path="/project/:projectId/context" element={<ContextPage />} />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/context"
            element={<ContextPage />}
          />
          <Route path="/home" element={<HomePage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  if (isMobile) {
    return (
      <div
        style={{
          width: "100vw",
          padding: 20,
          textAlign: "center",
          marginTop: 50,
        }}
      >
        <H4>&AI</H4>
        <Paragraph>
          This app is not supported for mobile use. Please access it on a
          computer.
        </Paragraph>
      </div>
    );
  }

  return (
    <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
      <ThemeProvider defaultTheme="system" storageKey="ui-theme">
        <AppContent />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
