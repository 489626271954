/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore, useAppStateStore, useCreateProjectStore } from "../store";
import { useNavigate } from "react-router-dom";
import {
  useApi,
  useVector,
  useLlm,
  useProject,
  useViz,
  useProcessReferences,
} from "./";
import { ApiResponse, ParentType, ProjectType } from "../types/types";
import { chunkProjects } from "../utils/projectUtils";

/**
 * @description Hook for handling portfolio operations
 */
const usePortfolio = () => {
  const navigate = useNavigate();
  const { postRequest, getRequest, handleError } = useApi();
  const { uploadToVDB } = useVector();
  const { getUserProjects } = useProject();
  const { addPDFToProject, addToDocumentsAdded } = useViz();
  const { generateFeatures } = useLlm();
  const { addAndProcessReferences, addAndProcessFiles } = useProcessReferences();
  const {
    updateCurrentPortfolioDetails,
    updateCurrentPortfolioId,
    currentPortfolioDetails,
    updateCurrentProjectDetails,
    updateCurrentProjectId,
    updateCurrentParent,
    currentPortfolioId,
  } = useProjectStore();

  const {
    addErrorMessage,
    addReferenceLoadingMessage,
    updateReferenceLoadingStatus,
    updateReferenceLoadingErrorMessage,
  } = useAppStateStore();

  const {
    updateSpinnerText,
    subjectNumbers,
    projectName,
    clientNumber,
    updateIsProjectCreationInProgress,
  } = useCreateProjectStore();

  /**
   * @description Fetches portfolio metadata and updates store
   * @param {string} portfolioId - The id of the portfolio to fetch metadata for
   */
  const getPortfolioMetadata = async (portfolioId: string): Promise<ApiResponse> => {
    try {
      if (!portfolioId) {
        return { success: false, message: "Portfolio ID is required" };
      }
      const response = await getRequest("get_portfolio_metadata", {
        portfolio_id: portfolioId,
      });
      const portfolioMetadata = response.data;
      updateCurrentProjectDetails({
        id: "",
        name: "",
      });
      updateCurrentProjectId("");
      updateCurrentParent(ParentType.PORT);
      updateCurrentPortfolioId(portfolioMetadata.id);
      updateCurrentPortfolioDetails({
        id: portfolioMetadata.id,
        name: portfolioMetadata.name,
        projects: portfolioMetadata.projects,
        type: portfolioMetadata.type,
        owner: portfolioMetadata.created_by,
      });

      return { success: true, data: portfolioMetadata };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    }
  };

  /**
   * @description Updates portfolio details
   * @param {string} portfolioId - The id of the portfolio to update
   * @param {Object} options - The options to update the portfolio with
   */
  const updatePortfolioDetails = async (
    portfolioId: string,
    options: any
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      portfolio_id: portfolioId,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_portfolio_details", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating portfolio metadata");
    }
  };

  /**
   * @description Adds a user to a portfolio
   * @param {string} portfolioId - The id of the portfolio to add the user to
   * @param {string} userEmail - The email of the user to add to the portfolio
   */
  const addUserToPortfolio = async (
    portfolioId: string,
    userId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_portfolio", {
        portfolio_id: portfolioId,
        user_id: userId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error adding user to portfolio");
    }
  };

  /**
   * @description Removes a user from a portfolio
   * @param {string} portfolioId - The id of the portfolio to remove the user from
   * @param {string} userEmail - The email of the user to remove from the portfolio
   */
  const removeUserFromPortfolio = async (
    portfolioId: string,
    userId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_portfolio", {
        portfolio_id: portfolioId,
        user_id: userId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error removing user from portfolio");
    }
  };

  /**
   * @description Fetches all users for a portfolio
   * @param {string} portfolioId - The id of the portfolio to fetch users for
   */
  const getUsersForPortfolio = async (
    portfolioId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_portfolio", {
        portfolio_id: portfolioId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Chunks portfolio projects and uploads them to the vector database, helper
   * @param projects - The projects to chunk and upload
   * @param chunkSize - The size of the chunks to upload
   */
  const chunkPortfolioProjects = async (projects: any[], chunkSize: number) => {
    const projectChunks = chunkProjects(projects, chunkSize);
    for (let i = 0; i < projectChunks.length; i++) {
      const chunk = projectChunks[i];
      const chunkPromises = chunk.map(async (project) => {
        const documents = [project.subject_id];
        const projectId = project.project_id;
        await generateFeatures(project.subject_id, projectId);
        const uploadResponse = await uploadToVDB(projectId, documents, false, true);
        if (!uploadResponse.success) {
          addErrorMessage(
            uploadResponse.message ||
              "An error occurred while creating this project. Try again later."
          );
        }
      });

      // Wait for all projects in the chunk to be processed
      await Promise.all(chunkPromises);
    }
  };

  /**
   * @description Creates a project from a patent or application number
   */
  const createPortfolioFromPatentNumbers = async (): Promise<ApiResponse> => {
    try {
      let finalName = projectName;
      if (!finalName) {
        finalName = `Untitled Portfolio`;
      }
      updateSpinnerText(`Creating portfolio...`);

      const res = await postRequest("post_create_portfolio", {
        patent_numbers: subjectNumbers,
        name: projectName,
        client_number: clientNumber,
      });
      const response = res.data.data;

      updateCurrentPortfolioId(response.id);
      updateCurrentPortfolioDetails({
        ...currentPortfolioDetails,
        id: response.id,
        name: projectName,
        type: ProjectType.PFA,
        clientNumber: response.client_number,
        projects: response.projects,
        owner: response.created_by,
      });

      await chunkPortfolioProjects(response.projects, 3);
      await getUserProjects();

      return {
        success: true,
        data: { ...response },
      };
    } catch (error) {
      return handleError(error, "Error creating portfolio. Try again later.");
    }
  };

  /**
   * @description Adds patents to a portfolio
   * @param {string} portfolioId - The id of the portfolio to add the patents to
   * @param {string[]} patentNumbers - The patents to add to the portfolio
   */
  const addPatentsToPortfolio = async (
    portfolioId: string,
    patentNumbers: string[]
    // addAllReferences: boolean
  ) => {
    try {
      const response = await postRequest("post_add_patents_to_portfolio", {
        patent_numbers: patentNumbers,
        portfolio_id: portfolioId,
        // add_portfolio_references: addAllReferences,
      });
      const responseData = response.data.data;

      addToDocumentsAdded(
        portfolioId,
        responseData.projects.map((project) => project.subject_id),
        true
      );

      await chunkPortfolioProjects(responseData.projects, 2);
      await getPortfolioMetadata(portfolioId);

      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error adding patents to portfolio. Try again later.");
    }
  };

  const addReferencesToPortfolio = async (
    portfolioId: string,
    referenceNumbers: string[]
  ) => {
    try {
      referenceNumbers.forEach((number) => {
        addReferenceLoadingMessage(
          number,
          currentPortfolioDetails.id,
          currentPortfolioDetails.name,
          "processing"
        );
      });

      const successfulReferenceNumbers: string[] = [];
      const failedReferenceNumbers: string[] = [];

      for (const project of currentPortfolioDetails.projects) {
        let response = await addAndProcessReferences(
          project.id,
          currentPortfolioDetails.name,
          referenceNumbers,
          true, // isCheckboxChecked
          false, // displayLoadingMessages
          true, // isPortfolioCreation
          true, // isCurrentParentPortfolio
          currentPortfolioId
        );
        successfulReferenceNumbers.push(...response.data.successfulNumbers);
        failedReferenceNumbers.push(...response.data.unsuccessfulNumbers);
        await getPortfolioMetadata(portfolioId);
      }

      successfulReferenceNumbers.forEach((number) => {
        updateReferenceLoadingStatus(number, "success", currentPortfolioDetails.id);
      });
      failedReferenceNumbers.forEach((number) => {
        updateReferenceLoadingErrorMessage(
          number,
          "Error adding reference. Please try again.",
          currentPortfolioDetails.id
        );
      });
      return { success: true };
    } catch (error) {
      return handleError(
        error,
        "Error adding references to portfolio. Try again later."
      );
    }
  };

  /**
   * @description Creates a project from a portfolio
   * @param projectId - The id of the project to create from the portfolio
   * @param portfolioId - The id of the portfolio to create the project from
   */
  const createProjectFromPortfolio = async (
    projectId: string,
    portfolioId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_create_project_from_portfolio", {
        project_id: projectId,
        portfolio_id: portfolioId,
      });

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error creating project from portfolio");
    }
  };

  /**
   * @description Removes projects from a portfolio
   * @param portfolioId - The id of the portfolio to remove the projects from
   * @param projectIds - The ids of the projects to remove from the portfolio
   */
  const removeProjectsFromPortfolio = async (
    portfolioId: string,
    projectIds: string[]
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_projects_from_portfolio", {
        portfolio_id: portfolioId,
        project_ids: projectIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error removing patents from portfolio");
    }
  };

  const getPatentsForAssignee = async (
    assignee: string[],
    priorityDate: string,
    expiryDate: string,
    cpcCodes: string[],
    types: string[]
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_patents_for_assignee", {
        assignee: assignee,
        priority_date_start: priorityDate,
        priority_date_end: expiryDate,
        cpc_codes: cpcCodes,
        types: types,
        limit: 1000,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching patents for assignee");
    }
  };

  const uploadFilesToPortfolio = async (
    files: File[],
    portfolioId: string,
    portfolioName: string
  ) => {
    files.forEach((file) => {
      addReferenceLoadingMessage(file.name, portfolioId, portfolioName, "uploading");
    });

    const successfulFiles: { name: string; id: string }[] = [];
    const failedFiles: { name: string; error: string }[] = [];
    for (const project of currentPortfolioDetails.projects) {
      let response = await addAndProcessFiles(
        project.id,
        portfolioName,
        files,
        true, // isCheckboxChecked
        false, // displayLoadingMessages
        ParentType.PORT,
        portfolioId
      );
      // successfulFiles.push(...response.data.successfulFiles);
      failedFiles.push(...response.data.unsuccessfulFiles);
      if (portfolioId === currentPortfolioId) {
        await getPortfolioMetadata(portfolioId);
      }
    }

    successfulFiles.push(
      ...files
        .filter(
          (file) => !failedFiles.some((failedFile) => failedFile.name === file.name)
        )
        .map((file) => ({ name: file.name, id: "" }))
    );

    successfulFiles.forEach((file) => {
      updateReferenceLoadingStatus(file.name, "success", portfolioId);
    });

    failedFiles.forEach((file) => {
      updateReferenceLoadingErrorMessage(file.name, file.error, portfolioId);
    });

    // const fileReferenceIds: string[] = [];
    // const fileIdToNameMap: Record<string, string> = {};
    // for (const file of files) {
    //   const promises = currentPortfolioDetails.projects.map((project) =>
    //     addPDFToProject(project.id, file, ParentType.PORT, currentPortfolioId)
    //   );
    //   const results: ApiResponse<any>[] = await Promise.all(promises);

    //   const successfulUpload = results.find((result) => result.success);

    //   if (successfulUpload) {
    //     const referenceId = successfulUpload.data[0].reference_id;
    //     fileReferenceIds.push(referenceId);
    //     fileIdToNameMap[referenceId] = file.name;
    //     updateReferenceLoadingStatus(
    //       file.name,
    //       "processing",
    //       currentPortfolioDetails.id
    //     );
    //   } else {
    //     addErrorMessage(`Error adding file: ${file.name}`);

    //   }
    // }

    // files.forEach((file) => {
    //   updateReferenceLoadingStatus(
    //     file.name,
    //     "processing",
    //     currentPortfolioDetails.id
    //   );
    // });

    // let successfulIds: string[] = [];
    // let failedIds: string[] = [];

    // // Upload all files to each project's vector database
    // for (const project of currentPortfolioDetails.projects) {
    //   if (fileReferenceIds.length > 0) {
    //     const response = await uploadToVDB(
    //       project.id,
    //       fileReferenceIds,
    //       false,
    //       true
    //     );
    //     successfulIds = response.data.successful_ids;
    //     failedIds = response.data.failed_ids;
    //   }
    // }
  };

  /**
   * Fetches and navigates to a project at the end of creation
   * @param {string} projectId - The id of the project to fetch
   */
  const fetchAndNavigateToPortfolio = async (portfolioId: string) => {
    updateCurrentPortfolioId(portfolioId);
    updateCurrentProjectId("");
    const response = await getPortfolioMetadata(portfolioId);
    const finalIds = response.data.projects.flatMap((project: any) =>
      [project.subject.id, project.strongest_reference.id].filter(Boolean)
    );
    await addToDocumentsAdded(portfolioId, finalIds, true);
    if (!response.success) {
      addErrorMessage(
        "Error fetching project data. Please navigate to project from home."
      );
      navigate("/home");
      return;
    }
    updateIsProjectCreationInProgress(false);
    navigate(`/portfolio/${portfolioId}`);
  };

  return {
    getPortfolioMetadata,
    updatePortfolioDetails,
    addUserToPortfolio,
    removeUserFromPortfolio,
    getUsersForPortfolio,
    createPortfolioFromPatentNumbers,
    addPatentsToPortfolio,
    createProjectFromPortfolio,
    removeProjectsFromPortfolio,
    getPatentsForAssignee,
    addReferencesToPortfolio,
    uploadFilesToPortfolio,
    fetchAndNavigateToPortfolio,
  };
};

export default usePortfolio;
