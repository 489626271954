/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { Separator } from "@/components/ui/separator";
import SubjectAbstractImages from "./SubjectAbstractImages";
import { DocumentMetadata } from "@/features/project/documents/components";
import { Patent, ElementType } from "@/types/types";
import PatentBody from "./PatentBody";
import { FeatureClaimList } from "@/features/project/documents/components";
import { cn } from "@/lib/utils";

interface ViewerPatentLayoutProps {
  patent: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>;
}

const ViewerPatentLayout: React.FC<ViewerPatentLayoutProps> = ({
  patent,
  citationText,
  highlightedRef,
}) => {
  if (!patent) return null;

  const isClaimHighlighted = patent.claims?.some((claim) => {
    const claimText = Object.values(claim)[0];
    return (
      typeof claimText === "string" &&
      claimText.slice(0, 75) === citationText?.slice(0, 75)
    );
  });
  return (
    <div className="flex h-full">
      <div
        className={cn(
          "w-[50%] overflow-y-auto overflow-x-hidden hide-scrollbar p-3"
        )}
      >
        <SubjectAbstractImages details={patent} />
        <PatentBody
          body={patent.fullBody || patent.body?.map((item) => item.text)}
          citationText={citationText}
          highlightedRef={highlightedRef}
        />
      </div>

      <Separator orientation="vertical" className="h-auto" />

      <div
        className={cn(
          "w-[50%] overflow-y-auto overflow-x-hidden p-3 hide-scrollbar"
        )}
      >
        <DocumentMetadata details={patent} showHead={true} subjectPage={true} />

        {patent.claims?.length > 0 && (
          <FeatureClaimList
            list={patent.claims}
            itemType={ElementType.CLAIM}
            startCollapsed={false}
            editingBool={false}
            citationText={isClaimHighlighted ? citationText : undefined}
            highlightedRef={
              isClaimHighlighted
                ? (highlightedRef as React.RefObject<HTMLTableRowElement>)
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

export default ViewerPatentLayout;
