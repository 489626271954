/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { DateTimePicker } from "@/components/ui/datetime-picker";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { InfoPopover } from "@/components";
import ItemTags from "./ItemTags";
import { PATENT_TYPES } from "@/types/types";

/**
 * @description Project name
 */
const SearchFilters: React.FC<{
  assignees: string[];
  setAssignees: (assignees: string[]) => void;
  toDate: Date | null;
  setToDate: (toDate: Date | null) => void;
  fromDate: Date | null;
  setFromDate: (fromDate: Date | null) => void;
  cpcCodes: string[];
  setCpcCodes: (cpcCodes: string[]) => void;
  countryCodes: string[];
  setCountryCodes: (countryCodes: string[]) => void;
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
  maxResults?: number;
  setMaxResults?: (maxResults: number) => void;
  useCpcCodes?: boolean;
  setUseCpcCodes?: (useCpcCodes: boolean) => void;
  showUseCpcCodes?: boolean;
  types: string[];
  setTypes: (types: string[]) => void;
  statuses: string[];
  setStatuses: (statuses: string[]) => void;
  disabled: boolean;
  isInCreate?: boolean;
}> = ({
  assignees,
  setAssignees,
  toDate,
  setToDate,
  fromDate,
  setFromDate,
  cpcCodes,
  setCpcCodes,
  keywords,
  setKeywords,
  maxResults,
  setMaxResults,
  useCpcCodes,
  setUseCpcCodes,
  disabled,
  types,
  setTypes,
  isInCreate,
}) => {
  const assigneeContent =
    "Assignee names require an exact match. For comprehensive results, enter all valid versions of an assignee name (e.g., 'Google', 'Google Inc.', 'Google, Inc.', etc.)";

  const renderItemTags = (
    label: string,
    items: string[],
    setItems: (items: string[]) => void,
    type: string,
    infoContent?: React.ReactNode
  ) => (
    <div className="space-y-2">
      <div className="flex items-center space-x-1">
        <Label className="mr-1">{label}</Label>
        {infoContent && <InfoPopover className="ml-1 mb-1" content={assigneeContent} />}
      </div>
      <ItemTags
        items={items}
        setItems={setItems}
        type={type}
        disabled={disabled}
        isInCreate={isInCreate}
      />
    </div>
  );

  const renderCpcTags = (
    label: string,
    items: string[],
    setItems: (items: string[]) => void,
    type: string,
    infoContent?: React.ReactNode
  ) => (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <Label>{label}</Label>
        {/* {infoContent && <InfoPopover content={infoContent} />} */}
      </div>
      {setUseCpcCodes && (
        <div className="flex items-center space-x-2">
          <CheckboxWithText
            id="useCpcCodes"
            checked={useCpcCodes}
            onCheckedChange={(checked) => setUseCpcCodes(checked as boolean)}
            disabled={disabled}
            label="Use CPC Codes"
          />
        </div>
      )}
      {(!setUseCpcCodes || useCpcCodes) && (
        <ItemTags
          items={items}
          setItems={setItems}
          type={type}
          disabled={disabled}
          isInCreate={isInCreate}
        />
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2">
          <Label>Result Type</Label>
          <div className="flex flex-wrap gap-4">
            {PATENT_TYPES.map((option) => (
              <div key={option} className="flex items-center space-x-2">
                <CheckboxWithText
                  id={option}
                  checked={types.includes(option)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setTypes([...types, option]);
                    } else {
                      setTypes(types.filter((item) => item !== option));
                    }
                  }}
                  disabled={disabled}
                  label={option}
                />
                {option === "International" && (
                  <span className="ml-2 text-sm text-gray-500">(Experimental)</span>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Label>Max Results</Label>
          <Input
            type="number"
            placeholder="Optional"
            value={maxResults}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (!isNaN(value) && setMaxResults) {
                setMaxResults(value);
              }
            }}
            disabled={disabled}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Label>Priority From</Label>
          <DateTimePicker
            value={fromDate}
            granularity="day"
            onChange={setFromDate}
            disabled={disabled}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Label>Priority To</Label>
          <DateTimePicker
            value={toDate}
            granularity="day"
            onChange={setToDate}
            disabled={disabled}
          />
        </div>
      </div>

      {/* Render ItemTags components */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderItemTags(
          "Assignees",
          assignees,
          setAssignees,
          "assignee",
          assigneeContent
        )}
        {renderCpcTags("CPC Codes", cpcCodes, setCpcCodes, "cpc")}
        {!isInCreate && renderItemTags("Keywords", keywords, setKeywords, "keyword")}
      </div>
    </div>
  );
};

export default SearchFilters;
