/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz, useVector, useProcessReferences } from "@/hooks";
import { AlertTriangle } from "lucide-react";
import { UploadedFileDisplay, DragAndDropFileArea } from "@/components";
import { ParentType } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";

interface UploadFileModalProps {
  open: boolean;
  handleClose: () => void;
  inContext?: boolean;
  handleContextAddClick?: (files: File[]) => void;
}

/**
 * @description Upload file modal
 */
const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  handleClose,
  inContext,
  handleContextAddClick,
}) => {
  const { addPDFToProject, fetchProjectData } = useViz();
  const { uploadToVDB } = useVector();
  const { addAndProcessFiles } = useProcessReferences();
  const location = useLocation();
  const inExplore = location.pathname.includes("explore");

  // Local state for file upload
  const [files, setFiles] = useState<File[]>([]);
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  // Global store state
  const { currentProjectId, currentProjectDetails, currentParent } =
    useProjectStore();
  const {
    addErrorMessage,
    addReferenceLoadingMessage,
    updateReferenceLoadingStatus,
  } = useAppStateStore();

  // Handle close modal
  const handleCloseClick = () => {
    setFiles([]);
    handleClose();
  };

  // Handle file change
  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      for (let selectedFile of selectedFiles) {
        const isFileTypeValid =
          selectedFile.type === "application/pdf" ||
          selectedFile.type === "text/plain";

        if (isFileTypeValid) {
          setFileTypeError(null);
          setFiles((prevFiles) => [...prevFiles, selectedFile]);
        } else {
          let errorMessage = "";
          if (!isFileTypeValid) {
            errorMessage += "File type is not supported.";
          }
          setFileTypeError(errorMessage);
        }
      }
    }
  };

  // Handle add more files
  const handleAddClick = async () => {
    handleCloseClick();
    if (files && files.length > 0) {
      if (inContext) {
        handleContextAddClick(files);
      } else {
        await handleAddFiles();
      }
    }
  };

  // Handle adding files to project
  const handleAddFiles = async () => {
    const existingFileNames = currentProjectDetails.referenceDetails.map(
      (ref) => ref.name
    );
    const newFiles = files.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    if (newFiles.length < files.length) {
      addErrorMessage(
        `${files.length - newFiles.length} file(s) skipped due to duplicate names.`
      );
    }

    await addAndProcessFiles(
      currentProjectId,
      currentProjectDetails.name,
      newFiles,
      isCheckboxChecked,
      true, // displayLoadingMessages
      ParentType.PROJ
    );
  };

  // Handle remove file from list
  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">Upload files</DialogTitle>
        </DialogHeader>

        <div className="flex-grow flex flex-col space-y-4 overflow-hidden">
          <DragAndDropFileArea
            handleFiles={handleFileChange}
            supportedFileTypes={[".pdf", ".txt"]}
          />

          {files.length > 0 && (
            <ScrollArea className="flex-grow max-h-[200px]">
              <div className="space-y-2">
                {files.map((file, index) => (
                  <UploadedFileDisplay
                    file={file}
                    key={index}
                    onRemove={() => handleRemoveFile(index)}
                    fullWidth={true}
                  />
                ))}
              </div>
            </ScrollArea>
          )}

          {fileTypeError && (
            <Alert variant="destructive">
              <AlertTriangle className="h-4 w-4" />
              <AlertDescription>{fileTypeError}</AlertDescription>
            </Alert>
          )}
        </div>

        <DialogFooter className="mt-4">
          <div className="flex items-center justify-between space-x-2 w-full">
            <div className="flex items-center space-x-2">
              {!inContext && !inExplore && (
                <CheckboxWithText
                  id="complete-charts"
                  checked={isCheckboxChecked}
                  onCheckedChange={(checked) =>
                    setIsCheckboxChecked(checked as boolean)
                  }
                  label="Complete claim and feature chart(s)"
                />
              )}
            </div>
            <Button onClick={handleAddClick}>Add</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFileModal;
