/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect, RefObject } from "react";

/**
 * @description Hook to check if the user is at the bottom of the page
 * @param {RefObject<HTMLElement>} ref - The reference to the element
 * @param {number} offset - The offset from the bottom
 * @returns {boolean} - The is at bottom
 */
const useAtBottom = (ref: RefObject<HTMLElement>, offset: number = 0): boolean => {
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        setIsAtBottom(scrollTop + clientHeight >= scrollHeight - offset);
      }
    };

    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, offset]);

  return isAtBottom;
};

export default useAtBottom;
