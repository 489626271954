/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useProjectStore } from "@/store";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
} from "@/components/ui/navigation-menu";
import { ParentType } from "@/types/types";
/**
 * @description Component for navigation tabs in invalidity project
 */
const InvalidityNavigationTabs: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Global state from store
  const {
    currentProjectId,
    updateCurrentProjectId,
    updateCurrentProjectDetails,
    clearChartData,
    currentPortfolioId,
    currentParent,
  } = useProjectStore();

  const getTabValue = (pathname: string): string => {
    if (pathname.includes("/subject")) {
      return "subject";
    } else if (pathname.includes("/references")) {
      return "references";
    } else if (pathname.includes("/charts")) {
      return "invalidity";
    } else if (pathname.includes("/search")) {
      return "search";
    } else if (pathname.includes("/chat")) {
      return "chat";
    } else if (pathname.includes("/context")) {
      return "context";
    } else if (pathname.includes("/portfolio")) {
      return "portfolio";
    }
  };

  const currentTabValue = getTabValue(location.pathname);

  // Debounce function to update window width
  const debounce = (
    func: (...args: any[]) => void,
    timeout = 200
  ): ((...args: any[]) => void) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(null, args);
      }, timeout);
    };
  };

  // Handle nav item selection
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    clearChartData();
    let path = "";
    if (currentParent === ParentType.PORT) {
      path = `/portfolio/${currentPortfolioId}`;
    }
    switch (newValue) {
      case "subject":
        navigate(`${path}/project/${currentProjectId}/subject`);
        break;
      case "references":
        navigate(`${path}/project/${currentProjectId}/references`);
        break;
      case "invalidity":
        navigate(`${path}/project/${currentProjectId}/charts`);
        break;
      case "search":
        navigate(`${path}/project/${currentProjectId}/search`);
        break;
      case "chat":
        navigate(`${path}/project/${currentProjectId}/chat`);
        break;
      case "context":
        navigate(`${path}/project/${currentProjectId}/context`);
        break;
      case "portfolio":
        updateCurrentProjectDetails({ id: "", name: "" });
        updateCurrentProjectId("");
        navigate(`${path}/subjects`);
        break;
      default:
        break;
    }
  };

  // Render tab label
  const renderNavItem = (text: string, value: string) => (
    <NavigationMenuItem
      className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
        currentTabValue === value
          ? "bg-accent text-accent-foreground rounded-full"
          : "text-muted-foreground"
      }`}
      onClick={(event) => {
        if (currentProjectId) {
          handleTabChange(event, value);
        }
      }}
    >
      {text}
    </NavigationMenuItem>
  );

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {currentParent === ParentType.PORT &&
          renderNavItem("Portfolio", "portfolio")}
        {renderNavItem("Subject", "subject")}
        {renderNavItem("Context", "context")}
        {renderNavItem("References", "references")}
        {renderNavItem("Charts", "invalidity")}
        {renderNavItem("Search", "search")}
        {renderNavItem("Chat", "chat")}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default memo(InvalidityNavigationTabs);
