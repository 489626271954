/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStateStore, useProjectStore } from "@/store";
import { useViz } from "@/hooks";
import { ProjectPage, Loader } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { SourceNumberButton } from "@/components";
import {
  PortfolioTable,
  UploadFilesToPortfolioModal,
  AddReferencesToPortfolioModal,
} from "./components";
import { cn } from "@/lib/utils";

/**
 * @description Portfolio page for project
 */
const PortfolioReferencesPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentPortfolioDetails, updateCurrentProjectId, currentPortfolioId } =
    useProjectStore();
  const [isLoading, setIsLoading] = useState(false);
  const { getPortfolioReferences, fetchProjectData } = useViz();

  const [references, setReferences] = useState<
    {
      id: string;
      number: string;
      name: string;
      title: string;
      is_patent: boolean;
      filing_date: string;
      publication_date: string;
      primary_examiner: string;
      assignee: string;
      subjects: { project_id: string; project_name: string }[];
    }[]
  >([]);

  // Page title
  const pageName = currentPortfolioDetails.name
    ? `${currentPortfolioDetails.name} - Portfolio - &AI`
    : "Portfolio - &AI";

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);

  useEffect(() => {
    const fetchReferences = async () => {
      setIsLoading(true);
      const result = await getPortfolioReferences(currentPortfolioDetails.id);
      if (result.success) {
        setReferences(result.data);
      }
      setIsLoading(false);
    };

    fetchReferences();
  }, [currentPortfolioDetails]);

  const handleSelectProject = async (projectId: string) => {
    await fetchProjectData(projectId);
    updateCurrentProjectId(projectId);
    navigate(`/portfolio/${currentPortfolioId}/project/${projectId}/subject`);
  };

  return (
    <ProjectPage pageName={pageName}>
      <div className="p-2">
        <div className="flex justify-end mb-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">Add References</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => setShowAddReferencesModal(true)}>
                Add Published Patents/Applications
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setShowUploadModal(true)}>
                Add Other Documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {isLoading ? (
          <Loader message="Loading..." />
        ) : (
          <div className="relative">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[12%]">Name</TableHead>
                  <TableHead className="w-[12%]">Number</TableHead>
                  <TableHead className="w-[26%]">Title</TableHead>
                  <TableHead className="w-[10%]">Assignee</TableHead>
                  <TableHead className="w-[10%]">Priority Date</TableHead>
                  <TableHead className="w-[20%]">Subjects</TableHead>
                </TableRow>
              </TableHeader>
              <TableCell className="p-0 m-0 w-[100%]" colSpan={6}>
                <div className={cn("flex flex-col", "h-[calc(100vh-155px)]")}>
                  <ScrollArea className="flex-grow">
                    <TableBody>
                      {references.map((reference) => (
                        <TableRow key={reference.id}>
                          <TableCellCondensed className="w-[12%]">
                            {reference.name || "—"}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[12%]">
                            {reference.number && reference.number.length < 20 ? (
                              <SourceNumberButton sourceNumber={reference.number} />
                            ) : (
                              "—"
                            )}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[26%]">
                            {reference.title || "—"}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[10%]">
                            {reference.assignee || "—"}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[10%]">
                            {reference.filing_date
                              ? new Date(reference.filing_date).toLocaleDateString()
                              : "—"}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[20%]">
                            {reference.subjects?.map((subject) => (
                              <Badge
                                key={subject.project_id}
                                variant="outline"
                                className="mr-1 mb-1 cursor-pointer"
                                onClick={() => handleSelectProject(subject.project_id)}
                              >
                                {subject.project_name}
                              </Badge>
                            ))}
                          </TableCellCondensed>
                        </TableRow>
                      ))}
                    </TableBody>
                  </ScrollArea>
                </div>
              </TableCell>
            </Table>
          </div>
        )}
      </div>

      <AddReferencesToPortfolioModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
      />
      <UploadFilesToPortfolioModal
        open={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
      />
    </ProjectPage>
  );
};

export default PortfolioReferencesPage;
