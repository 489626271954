/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { useAppStateStore, useProjectStore } from "@/store";
import { useViz, useDocumentTitle, useProject, usePortfolio } from "@/hooks";
import { ProjectTypeCard, ProjectsTable } from "./components";
import SidebarPage from "@/components/layout/SidebarPage";
import { Project, ParentType, ProjectType } from "@/types/types";
import { Loader } from "@/components";

/**
 * @description Home page component
 */
function HomePage() {
  useDocumentTitle("Home - &AI");
  const navigate = useNavigate();
  const { fetchProjectData, getSearchChatProjectId } = useViz();
  const { getPortfolioMetadata } = usePortfolio();
  const { getUserProjects } = useProject();
  const { user } = useAuthInfo();

  // Global state from store
  const {
    currentProjectId,
    updateCurrentProjectId,
    updateCurrentPortfolioId,
    updateCurrentParent,
    updateCurrentPortfolioDetails,
    updateCurrentProjectDetails,
    currentProjectDetails,
    currentPortfolioDetails,
  } = useProjectStore();
  const { addErrorMessage, searchChatProjectId, updateIsLoading } =
    useAppStateStore();

  const pageContentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExploreProjectId = useCallback(async () => {
    await getSearchChatProjectId();
  }, [getSearchChatProjectId]);

  // Fetches user projects on the first home page load
  const fetchProjectsData = useCallback(async () => {
    if (!user?.email) return;
    setIsLoading(true);
    const projectsResponse = await getUserProjects();
    if (!projectsResponse.success) {
      addErrorMessage("Error fetching projects. Please try again later.");
    }
    setIsLoading(false);
  }, [user?.email, getUserProjects, addErrorMessage]);

  // Handle project selection
  const handleClickProject = useCallback(
    async (project: Project) => {
      const id = project.project_id || project.id;
      if (project.type !== ProjectType.PFA) {
        updateCurrentParent(ParentType.PROJ);
        if (id === currentProjectId) {
          navigate(`/project/${id}/subject`);
        } else {
          updateCurrentProjectId(id);
          updateCurrentProjectDetails({
            ...currentProjectDetails,
            id: id,
            name: project.name,
          });
          navigate(`/project/${id}/subject`);
          updateIsLoading(true);
          await fetchProjectData(id);
          updateIsLoading(false);
        }
      } else {
        updateCurrentParent(ParentType.PORT);
        updateCurrentPortfolioId(id);
        updateCurrentProjectId("");
        updateCurrentProjectDetails({
          id: "",
          name: "",

          subjectId: "",
          referenceIds: [],
          referenceDetails: [],
          documentIds: [],
          keywords: [],
        });
        updateCurrentPortfolioDetails({
          ...currentPortfolioDetails,
          id: id,
          name: project.name,
        });
        navigate(`/portfolio/${id}/subjects`);
        updateIsLoading(true);
        await getPortfolioMetadata(id);
        updateIsLoading(false);
      }
    },
    [
      currentProjectId,
      fetchProjectData,
      updateCurrentProjectId,
      updateCurrentParent,
      updateCurrentPortfolioId,
      updateCurrentPortfolioDetails,
      currentPortfolioDetails,
      navigate,
      getPortfolioMetadata,
      updateIsLoading,
    ]
  );

  useEffect(() => {
    if (!searchChatProjectId) {
      fetchExploreProjectId();
    }
  }, [searchChatProjectId, fetchExploreProjectId]);

  useEffect(() => {
    fetchProjectsData();
  }, []);

  return (
    <SidebarPage>
      <div className="bg-background p-6" ref={pageContentRef}>
        <div id="createNewProject" className="mb-6 flex flex-col gap-2">
          <p className="text-base font-medium p-1 h-auto whitespace-nowrap">
            Start a new project
          </p>
          <div className="flex space-x-4 overflow-x-auto">
            <ProjectTypeCard
              onClick={() => navigate("/create-project")}
              type={ProjectType.INV}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-application-project")}
              type={ProjectType.APP}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-portfolio-project")}
              type={ProjectType.PFA}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <ProjectsTable
            tableType="active"
            handleClickProject={handleClickProject}
            isInArchive={false}
            height="h-[calc(100vh-190px)]"
          />
        )}
      </div>
    </SidebarPage>
  );
}

export default HomePage;
