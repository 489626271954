/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { usePortfolio } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import {
  parseCommaSeparatedString,
  isLikelyPatentNumber,
} from "@/utils/dataUtils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";

interface AddReferencesToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddReferencesToPortfolioModal: React.FC<
  AddReferencesToPortfolioModalProps
> = ({ open, handleClose }) => {
  const { addReferencesToPortfolio } = usePortfolio();

  // Modal state
  const [patentNumbers, setPatentNumbers] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [invalidPatentNumbers, setInvalidPatentNumbers] = useState<string[]>(
    []
  );

  // Global store state
  const { currentPortfolioId } = useProjectStore();

  // Reset the modal state on close
  const resetModal = () => {
    setPatentNumbers("");
    setError("");
    setInvalidPatentNumbers([]);
  };

  // Handle the add to project button click
  const handleAddClick = async () => {
    const patentNumbersArray = parseCommaSeparatedString(patentNumbers);

    // Make sure the portfolio doesn't exceed the maximum number of patents

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    for (const patentNumber of patentNumbersArray) {
      if (!isLikelyPatentNumber(patentNumber)) {
        setInvalidPatentNumbers([...invalidPatentNumbers, patentNumber]);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }

    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setError("No valid patent or application numbers input.");
      return;
    }

    // const key = nanoid();
    // addLoadingMessage("Adding references to all subjects...", key);
    handleClose();

    try {
      if (validPatentNumbers.length > 0) {
        await addReferencesToPortfolio(currentPortfolioId, validPatentNumbers);
      }
      // await getPortfolioMetadata(currentProjectId);
      // removeLoadingMessage(key);
    } catch (error) {
      setError("Error adding references to portfolio. Try again later.");
    }
    resetModal();
  };

  const handlePatentNumbersChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setPatentNumbers(newValue);
    if (newValue !== "") {
      setError("");
    }
    setInvalidPatentNumbers([]);
  };
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add references to all subjects</DialogTitle>
          <DialogDescription>
            Enter a comma-separated list of published patents and applications
            numbers. If no country prefix is provided, it is assumed to be a US
            patent.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div className="space-y-2">
            <AutosizeTextarea
              id="patent-numbers"
              placeholder="US10952930, CN10952931B2, US20220183193A1, 10782732"
              value={patentNumbers}
              onChange={handlePatentNumbersChange}
              className={error ? "border-red-500" : ""}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <Button onClick={handleAddClick} disabled={patentNumbers === ""}>
            Add References
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddReferencesToPortfolioModal;
