import React, { useState, useEffect } from "react";
import useViz from "@/hooks/useViz";
import { useProjectStore, useAppStateStore } from "@/store";
import { splitId, getHighestRank } from "@/utils/projectUtils";
import {
  Table,
  TableBody,
  DataTableHead,
  DataTableRowOutline,
  TableHeader,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PatentViewer } from "@/components";
import { ChartColors, InvalidityCitation, ElementType } from "@/types/types";
import { DataTableRow } from "./index";

interface DataTableProps {
  fetchReferenceChartData: () => void;
}

const DataTable: React.FC<DataTableProps> = ({ fetchReferenceChartData }) => {
  const {
    deleteReferenceCitation,
    updateReferenceCitation,
    addReferenceCitation,
    uploadImageToS3,
    getFullDocument,
  } = useViz();

  const {
    currentProjectId,
    currentProjectDetails,
    chartData,
    updateChartData,
    summaryChartData,
    updateSummaryChartData,
    selectedElementType,
  } = useProjectStore();
  const addErrorMessage = useAppStateStore((state) => state.addErrorMessage);
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProjectDetails.documentsToNumbers
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProjectDetails.documentsToNicknames
  );
  const [editBooleanArray, setEditBooleanArray] = useState<boolean[]>(
    chartData.map(() => false)
  );

  const referenceIdList =
    chartData.length > 0
      ? Object.keys(chartData[0]).filter(
          (key) =>
            key !== "claim_number" &&
            key !== "claim_text" &&
            key !== "invalidity_id"
        )
      : [];

  const [claimEditIndex, setClaimEditIndex] = useState<number | null>(null);
  const [claimIsAdding, setClaimIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [addingCitationKey, setAddingCitationKey] = useState<string>("");
  const [showPatentViewer, setShowPatentViewer] = useState<boolean>(false);
  const [patentViewerPatent, setPatentViewerPatent] = useState<any>(null);

  const handleShowPatentViewer = async (refId: string) => {
    const response = await getFullDocument(currentProjectId, refId);
    if (response.success) {
      setPatentViewerPatent(response.data);
      setShowPatentViewer(true);
    } else {
      addErrorMessage(
        `Error getting patent ${refId} from project ${currentProjectDetails.name} `
      );
      handleClosePreview();
    }
  };

  const handleClosePreview = () => {
    setPatentViewerPatent(null);
    setShowPatentViewer(false);
  };

  const updateEditBooleanArray = (index: number, value: boolean) => {
    setEditBooleanArray((currentStates) => {
      let newArray = [...currentStates];
      newArray[index] = value;
      return newArray;
    });
  };

  useEffect(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setClaimIsAdding(false);
    setIsEditing(false);
  }, [selectedElementType]);

  /**
   * @description Adds a new citation to the chart data
   * @param {number} index - the index of the row to add the citation to
   * @param {string} referenceId - the id of the reference to add the citation to
   */
  const handleAddCitation = (index: number, referenceId: string) => {
    setIsEditing(true);
    const newCitation = {
      paragraph: "",
      text: "",
      color: ChartColors.GREEN,
      figureUrls: [],
    };
    const newChartData = [...chartData];
    const refCitationArray = [...newChartData[index][referenceId].citations];
    const nextIndex = refCitationArray.length;
    refCitationArray.push(newCitation);
    newChartData[index][referenceId].citations = refCitationArray;
    const newCitationKey = `${index}-${referenceId}-${nextIndex}`;
    setAddingCitationKey(newCitationKey);
    updateChartData(newChartData);
  };

  /**
   * @description Adds an image to a citation
   * @param {number} index - the index of the row to add the image to
   * @param {string} referenceId - the id of the reference to add the image to
   * @param {number} citationIndex - the index of the citation to add the image to
   * @param {File} file - the file to add to the citation
   * @param {InvalidityCitation} citation - the citation to add the image to
   */
  const handleAddImageToCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    file: File,
    citation: InvalidityCitation
  ) => {
    const response = await uploadImageToS3(file);
    const imageUrl = response.data.url;

    const attachResponse = await handleAttachReferenceFiguresToCitation(
      index,
      referenceId,
      citationIndex,
      [imageUrl],
      citation
    );
    if (!attachResponse) {
      addErrorMessage(
        `Error adding image to citation ${citationIndex} from project ${currentProjectDetails.name} `
      );
    }
  };

  /**
   * @description Attaches an image to a citation
   * @param {number} index - the index of the row to add the image to
   * @param {string} referenceId - the id of the reference to add the image to
   * @param {number} citationIndex - the index of the citation to add the image to
   * @param {Array} figureUrls - the URLs of the figures to add to the citation
   */
  const handleAttachReferenceFiguresToCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrls: string[],
    citation: InvalidityCitation
  ) => {
    const newChartData = [...chartData];
    const newCitation =
      newChartData[index][referenceId]["citations"][citationIndex];

    // Filter out URLs that are already in the citation's figure_urls
    // // Combine existing figure_urls with the new, filtered ones
    let newFigureUrls = figureUrls;
    if (!newCitation.figure_urls) {
      newCitation.figure_urls = [...newFigureUrls];
    } else {
      newFigureUrls = figureUrls.filter(
        (url) => !newCitation.figure_urls.includes(url)
      );
      newCitation.figure_urls = [...newCitation.figure_urls, ...newFigureUrls];
    }
    updateChartData(newChartData);
    const response = await updateReferenceCitation(citation.id, {
      new_figure_urls: newFigureUrls,
    });
    if (!response.success) {
      addErrorMessage(
        `Error adding figure to citation ${citationIndex} from project ${currentProjectDetails.name} `
      );
      return false;
    }
    return true;
  };

  /**
   * @description Removes an image from a citation
   * @param {number} index - the index of the row to remove the image from
   * @param {string} referenceId - the id of the reference to remove the image from
   * @param {number} citationIndex - the index of the citation to remove the image from
   * @param {string} figureUrl - the URL of the figure to remove from the citation
   */
  const removeImageFromCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrl: string,
    citation: InvalidityCitation
  ) => {
    const newChartData = [...chartData];
    const newCitation =
      newChartData[index][referenceId]["citations"][citationIndex];

    // Filter out the specific URL from the citation's figure_urls
    newCitation.figure_urls = newCitation.figure_urls.filter(
      (url: string) => url !== figureUrl
    );
    updateChartData(newChartData);

    const response = await updateReferenceCitation(citation.id, {
      old_figure_urls: [figureUrl],
    });
    if (!response.success) {
      addErrorMessage(
        `Error removing figure from citation ${citationIndex} from project ${currentProjectDetails.name} `
      );
    }
  };

  /**
   * @description Saves a citation after adding/editing
   * @param {number} index - the index of the row to save the citation to
   * @param {string} referenceId - the id of the reference to save the citation to
   * @param {number} citationIndex - the index of the citation to save
   * @param {string} citationTextEdit - the text of the citation to save
   * @param {string} citationParagraphEdit - the paragraph of the citation to save
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   * @param {Array} figureUrls - the URLs of the figures to save to the citation
   */
  const handleSaveCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationParagraphEdit: string,
    isAddingCitation: boolean,
    figureUrls: string[],
    citation: InvalidityCitation,
    invalidityId: string,
    figureRefs: string[]
  ) => {
    setIsEditing(false);
    let newCitation: InvalidityCitation;
    let newChartData = [...chartData];
    let color: string;
    if (isAddingCitation) {
      color = chartData[index][referenceId]["citations"][citationIndex].color;
      const updatedCitations = [...chartData[index][referenceId]["citations"]];
      updatedCitations.splice(citationIndex, 1); // remove empty citation

      // Determine whether to update the color in the summary table and update if so
      const otherColors = extractOtherColors(index, citationIndex, referenceId);
      const isColorUpdate = isSummaryColorUpdateRequired(
        "#D3D3D3",
        color,
        otherColors
      );
      if (isColorUpdate) {
        const newSummaryChartData = [...summaryChartData];
        newSummaryChartData[index][referenceId] = color;
        updateSummaryChartData(newSummaryChartData);
      }

      const addResponse = await addReferenceCitation(
        invalidityId,
        referenceId,
        color,
        citationTextEdit,
        citationParagraphEdit,
        currentProjectId,
        selectedElementType === ElementType.FEATURE,
        figureUrls,
        figureRefs,
        chartData[index].claim_number
      );

      if (!addResponse.success) {
        addErrorMessage(
          `Error adding citation ${citationParagraphEdit} from project ${currentProjectDetails.name} `
        );
      } else {
        newCitation = addResponse.data;
        updatedCitations.push(newCitation);
        const newChartData = chartData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                [referenceId]: {
                  ...item[referenceId],
                  citations: updatedCitations,
                },
              }
            : { ...item }
        );
        updateChartData(newChartData);
      }

      setAddingCitationKey("");
      // fetchChartData();
    } else {
      if (citationParagraphEdit !== citation.paragraph) {
        newChartData[index][referenceId]["citations"][citationIndex].paragraph =
          citationParagraphEdit;
      }
      if (citationTextEdit !== citation.text) {
        newChartData[index][referenceId]["citations"][citationIndex].text =
          citationTextEdit;
      }
      updateChartData(newChartData);

      const response = await updateReferenceCitation(citation.id, {
        new_paragraph: citation.paragraph,
        new_text: citation.text,
      });
      if (!response.success) {
        addErrorMessage(
          `Error updating citation ${citationParagraphEdit} from project ${currentProjectDetails.name} `
        );
      }
    }
  };

  /**
   * @description Deletes a citation
   * @param {number} itemIndex - the index of the row to delete the citation from
   * @param {number} citationIndex - the index of the citation to delete
   * @param {string} referenceId - the id of the reference to delete the citation from
   * @param {InvalidityCitation} citation - the paragraph of the citation to delete
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceDelete = async (
    itemIndex: number,
    citationIndex: number,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => {
    const newChartData = [...chartData];
    const updatedCitations = [
      ...chartData[itemIndex][referenceId]["citations"],
    ];

    const currentHighestColor = getHighestRank(
      updatedCitations.map((c) => c.color)
    );

    updatedCitations.splice(citationIndex, 1); // remove citation at citationIndex
    newChartData[itemIndex][referenceId]["citations"] = updatedCitations;

    updateChartData(newChartData);
    if (updatedCitations.length === 0) {
      summaryTableDeleteUpdate(itemIndex, referenceId);
    } else {
      // Calculate the new highest color after deletion
      const newHighestColor = getHighestRank(
        updatedCitations.map((c) => c.color)
      );

      // If the highest color has changed, update the summary table
      if (newHighestColor !== currentHighestColor) {
        const newSummaryChartData = [...summaryChartData];
        newSummaryChartData[itemIndex][referenceId] = newHighestColor;
        updateSummaryChartData(newSummaryChartData);
      }
    }
    if (!isAddingCitation) {
      const response = await deleteReferenceCitation(citation.id);
      if (!response.success) {
        addErrorMessage(
          `Error deleting citation ${citation.paragraph} from project ${currentProjectDetails.name} `
        );
      }
    }
  };

  /**
   * @description Retrieves the invalidity for a row
   * @param {number} rowIndex - the index of the row to retrieve the invalidity from
   * @param {string} referenceId - the id of the reference to retrieve the invalidity from
   * @param {string} newInvalidity - the new invalidity to set
   */
  const retrieveInvalidityForItem = async (
    rowIndex: number,
    referenceId: string,
    newInvalidity: InvalidityCitation[]
  ) => {
    // const newChartData = [...chartData];
    // newChartData[rowIndex][referenceId]["citations"] = newInvalidity;

    // updateChartData(newChartData);
    fetchReferenceChartData();

    summaryTableNewInvalidityUpdate(rowIndex, referenceId, newInvalidity);
  };

  const summaryTableNewInvalidityUpdate = (
    claimIndex: number,
    reference_id: string,
    newInvalidity: InvalidityCitation[]
  ) => {
    const colors: string[] = [];
    // const invalidityArray = JSON.parse(newInvalidity);
    newInvalidity.forEach((citation: InvalidityCitation) => {
      if (citation.color) {
        colors.push(citation.color);
      }
    });

    const color = getHighestRank(colors);
    const newSummaryChartData = [...summaryChartData];

    // Ensure the claimIndex is within bounds
    if (!newSummaryChartData[claimIndex]) {
      newSummaryChartData[claimIndex] = {};
    }

    newSummaryChartData[claimIndex][reference_id] = color;
    updateSummaryChartData(newSummaryChartData);
  };

  const summaryTableDeleteUpdate = (
    claimIndex: number,
    referenceId: string
  ) => {
    const newSummaryChartData = [...summaryChartData];
    newSummaryChartData[claimIndex][referenceId] = ChartColors.GRAY;
    updateSummaryChartData(newSummaryChartData);
  };

  /**
   * @description Updates the color of a citation
   * @param {number} index - the index of the row to update the color of
   * @param {number} citation - the index of the citation to update the color of
   * @param {string} currentColor - the current color of the citation
   * @param {string} color - the new color to set
   * @param {string} referenceId - the id of the reference to update the color of
   */
  const summaryTableColorUpdate = async (
    index: number,
    citation: number,
    currentColor: string,
    color: string,
    referenceId: string
  ) => {
    const otherColors = extractOtherColors(index, citation, referenceId);

    const isUpdateRequired = isSummaryColorUpdateRequired(
      currentColor,
      color,
      otherColors
    );

    if (!isUpdateRequired) {
      return;
    }

    const newSummaryChartData = [...summaryChartData];
    newSummaryChartData[index][referenceId] = color;
    updateSummaryChartData(newSummaryChartData);
  };

  /**
   * @description Extracts the colors of the other citations in a row
   * @param {number} index - the index of the row to extract the colors from
   * @param {number} citation - the index of the citation to exclude
   * @param {string} referenceId - the id of the reference to extract the colors from
   */
  const extractOtherColors = (
    index: number,
    citation: number,
    referenceId: string
  ) => {
    const colors: string[] = [];
    const row = chartData[index][referenceId]["citations"];
    if (Array.isArray(row)) {
      row.forEach((item, itemIndex) => {
        // Check if the current citation index is not the one to be excluded and has a color property
        if (itemIndex !== citation && item.color) {
          colors.push(item.color);
        }
      });
    }
    return colors;
  };

  /**
   * @description Checks if a color update is required in the summary table
   * @param {string} currentColor - the current color of the citation
   * @param {string} newColor - the new color to set
   * @param {Array} otherColors - the colors of the other citations in the row
   */
  const isSummaryColorUpdateRequired = (
    currentColor: string,
    newColor: string,
    otherColors: string[]
  ) => {
    // Calculate the highest rank in the original set and the new set
    const originalHighestRank = getHighestRank([currentColor, ...otherColors]);
    const newHighestRank = getHighestRank([newColor, ...otherColors]);

    // Update required if the highest rank in the new set is different from the original set
    return newHighestRank !== originalHighestRank;
  };

  /**
   * @description Updates the color of a citation in the chart data
   * @param {number} index - the index of the row to update the color of
   * @param {number} citationIndex - the index of the citation to update the color of
   * @param {string} color - the new color to set
   * @param {string} referenceId - the id of the reference to update the color of
   * @param {string} citationParagraph - the paragraph of the citation to update
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceColorUpdate = async (
    index: number,
    citationIndex: number,
    color: string,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => {
    // Get current color to determine whether FE ummary table update is needed
    const currentColor =
      chartData[index][referenceId]["citations"][citationIndex].color;

    // Change the color on the frontend
    const newChartData = [...chartData];
    newChartData[index][referenceId]["citations"][citationIndex].color = color;
    updateChartData(newChartData);

    if (!isAddingCitation) {
      // Update summary table color if needed
      summaryTableColorUpdate(
        index,
        citationIndex,
        currentColor,
        color,
        referenceId
      );

      const updateResponse = await updateReferenceCitation(citation.id, {
        new_color: color,
      });
      if (!updateResponse.success) {
        addErrorMessage(
          `Error updating citation color for ${citationIndex} from project ${currentProjectDetails.name} `
        );
      }
    }
  };

  const getColumnWidth = () => {
    // Divide the 80% width among the reference ID columns
    return referenceIdList.length > 0
      ? `${75 / referenceIdList.length}%`
      : "auto";
  };

  const referenceColumnWidth = getColumnWidth();

  return (
    <div>
      {showPatentViewer && (
        <PatentViewer
          open={showPatentViewer}
          handleClose={handleClosePreview}
          patent={patentViewerPatent}
          citationText={""}
        />
      )}
      <Table>
        <TableHeader>
          <DataTableRowOutline>
            <DataTableHead />
            <DataTableHead className="font-bold">Element</DataTableHead>
            {referenceIdList.map((refId) => (
              <DataTableHead
                key={`${refId}-header`}
                style={{ width: referenceColumnWidth }}
              >
                <div className="flex justify-between items-center">
                  <span className="font-bold">
                    Invalidity for {splitId(documentsToNumbers?.[refId] ?? "")}{" "}
                    {documentsToNicknames?.[refId] &&
                      `- ${documentsToNicknames?.[refId]}`}
                  </span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleShowPatentViewer(refId)}
                        >
                          <EyeOpenIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View full document</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </DataTableHead>
            ))}
          </DataTableRowOutline>
        </TableHeader>
        <TableBody>
          {chartData.map((row, index) => (
            <DataTableRow
              key={index}
              row={row}
              rowIndex={index}
              editBooleanArray={editBooleanArray}
              updateEditBooleanArray={updateEditBooleanArray}
              handleAddCitation={handleAddCitation}
              handleAddImageToCitation={handleAddImageToCitation}
              removeImageFromCitation={removeImageFromCitation}
              claimEditIndex={claimEditIndex}
              setClaimEditIndex={setClaimEditIndex}
              claimIsAdding={claimIsAdding}
              setClaimIsAdding={setClaimIsAdding}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              addingCitationKey={addingCitationKey}
              setEditBooleanArray={setEditBooleanArray}
              handleSourceDelete={handleSourceDelete}
              handleSourceColorUpdate={handleSourceColorUpdate}
              referenceColumnWidth={referenceColumnWidth}
              retrieveInvalidityForItem={retrieveInvalidityForItem}
              handleSaveCitation={handleSaveCitation}
              handleAttachReferenceFiguresToCitation={
                handleAttachReferenceFiguresToCitation
              }
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
