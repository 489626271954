/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore } from "@/store";
import { X, CheckCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";

interface ReferenceFigureSelectModalProps {
  open: boolean;
  handleClose: () => void;
  refId: string;
  handleAttachFigures: (images: string[]) => void;
  isAddingCitation: boolean;
  setReferenceFiguresToAttach: (images: string[]) => void;
  existingFigureUrls: string[];
}

/**
 * @description Renders a modal for selecting reference figure from the document to add to a citation
 */
const ReferenceFigureSelectModal: React.FC<ReferenceFigureSelectModalProps> = ({
  open,
  handleClose,
  refId,
  handleAttachFigures,
  isAddingCitation,
  setReferenceFiguresToAttach,
  existingFigureUrls,
}) => {
  // Global state from store
  const { selectedReferenceDetails } = useProjectStore();

  // Local state setup
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const selectedDetails =
    selectedReferenceDetails.find((ref) => ref.id === refId) || {};
  const imageUrls: string[] = selectedDetails.figure_urls || [];
  const [filteredImageUrls, setFilteredImageUrls] = useState<string[]>([]);

  const toggleSelectImage = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageUrl)) {
        return prevSelectedImages.filter((img) => img !== imageUrl);
      } else {
        return [...prevSelectedImages, imageUrl];
      }
    });
  };

  useEffect(() => {
    setFilteredImageUrls(
      imageUrls.filter((url) => !existingFigureUrls.includes(url))
    );
  }, [open]);

  // Handle close modal click
  const handleCloseClick = () => {
    setSelectedImages([]);
    handleClose();
  };

  // Handle submit add figures to citation
  const handleSubmit = async () => {
    if (isAddingCitation) {
      setReferenceFiguresToAttach(selectedImages);
    } else {
      handleAttachFigures(selectedImages);
    }
    setSelectedImages([]);
    handleClose();
  };

  // Reset selected images state on open
  useEffect(() => {
    setSelectedImages([]);
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Select reference figure(s)</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh] w-full p-2">
          <div className="grid grid-cols-2 gap-4">
            {filteredImageUrls.map((imageUrl, index) => (
              <div
                key={index}
                className={`relative cursor-pointer rounded-md border-2 ${
                  selectedImages.includes(imageUrl)
                    ? "border-blue-500"
                    : "border-gray-200"
                }`}
                onClick={() => toggleSelectImage(imageUrl)}
              >
                <img
                  src={imageUrl}
                  alt={`Image ${index}`}
                  className="h-[200px] w-auto object-cover"
                />
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute right-1 top-1 bg-background"
                >
                  <CheckCircle
                    className={`h-5 w-5 ${
                      selectedImages.includes(imageUrl)
                        ? "text-blue-500"
                        : "text-gray-300"
                    }`}
                  />
                </Button>
              </div>
            ))}
          </div>
        </ScrollArea>
        <div className="mt-2 flex justify-end">
          <Button onClick={handleSubmit} disabled={selectedImages.length === 0}>
            Add to Citation
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReferenceFigureSelectModal;
