/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect } from "react";
import { useAppStateStore, useAdminStore } from "@/store";
import { useOrgs } from "@/hooks";
import {
  Table,
  TableBody,
  TableHead,
  TableCellCondensed,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { AddOrganizationModal } from ".";
import { Organization } from "@/types/types";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { H4 } from "@/components/ui/typography";
import { PlusIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

/**
 * @description Organization details table
 */
function OrganizationDetailsTable() {
  const navigate = useNavigate();
  const { createOrganization, getSuperAdminDetails, getAdminDetailsForOrg } = useOrgs();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();
  const {
    superAdminOrganizations,
    clearOrgLevelStore,
    updateSuperAdminSelectedOrganization,
  } = useAdminStore();

  const [isAddOrgModalOpen, setAddOrgModalOpen] = useState(false);

  const columns = [
    { key: "name", label: "Name" },
    { key: "seats", label: "Seats" },
    { key: "projects", label: "Projects" },
    { key: "documentsAddedCount", label: "Documents Added" },
    { key: "documentsExportedCount", label: "Documents Exported" },
    { key: "amountDue", label: "Amount Due" },
    { key: "amountSpent", label: "Amount Spent" },
  ];

  useEffect(() => {
    if (!superAdminOrganizations) {
      getSuperAdminDetails().catch(() =>
        addErrorMessage("Error fetching organization details")
      );
    }
  }, [superAdminOrganizations, getSuperAdminDetails]);

  const handleOrgClick = (orgId: string) => {
    clearOrgLevelStore();
    updateSuperAdminSelectedOrganization(
      superAdminOrganizations.find((org) => org.organizationId === orgId)
    );

    navigate(`/superadmin/organizations/${orgId}`);
    getAdminDetailsForOrg(orgId).catch(() =>
      addErrorMessage("Error fetching organization details")
    );
  };

  const addNewOrg = async (newOrg: Organization) => {
    const response = await createOrganization(newOrg);
    if (!response.success) {
      addErrorMessage(response.message || "");
      return;
    }
    addSuccessMessage("Organization created: " + newOrg.organizationName);
    // const usageResponse = await getUsageStatistics(newOrg.organization_id);
    // if (!usageResponse.success) {
    //   addErrorMessage(usageResponse.message || "");
    //   return;
    // }
    // superAdminOrganizations.push(usageResponse.data);
    // updateSuperAdminOrganizations([...superAdminOrganizations]);
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <H4>Organizations</H4>
          <Button size="icon" onClick={() => setAddOrgModalOpen(true)}>
            <PlusIcon />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((col) => (
                <TableHead key={col.key}>{col.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {superAdminOrganizations.map((org) => (
              <TableRow
                onClick={() => handleOrgClick(org.organizationId)}
                key={`${org.organizationName}-${org.contractStart}`}
              >
                {columns.map((col) =>
                  org[col.key] !== null ? (
                    <TableCellCondensed key={col.key}>
                      {col.key.includes("Date")
                        ? new Date(org[col.key]).toLocaleDateString()
                        : org[col.key]}
                    </TableCellCondensed>
                  ) : (
                    <TableCellCondensed>-</TableCellCondensed>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      <AddOrganizationModal
        open={isAddOrgModalOpen}
        onClose={() => setAddOrgModalOpen(false)}
        onAddOrg={addNewOrg}
      />
    </Card>
  );
}

export default OrganizationDetailsTable;
