/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useProjectStore } from "../store";
import { useVector, useViz } from ".";
import { ParentType } from "../types/types";
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProcessReferences = () => {
  const { currentProjectId, currentParent, currentPortfolioId, currentProjectDetails } =
    useProjectStore();
  const {
    updateReferenceLoadingStatus,
    addReferenceLoadingMessage,
    updateReferenceLoadingErrorMessage,
  } = useAppStateStore();
  const { uploadToVDB } = useVector();
  const { addReferencesToProject, fetchProjectData, addPDFToProject } = useViz();

  /**
   * Chunks an array into smaller arrays, helper function for processReferences
   * @param {string[]} array - The array to chunk
   * @param {number} chunkSize - The size of the chunk to process
   * @returns An array of chunks
   */
  async function chunkArray(array: string[], chunkSize: number): Promise<string[][]> {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const addAndProcessReferences = async (
    projectId: string,
    projectName: string,
    refNums: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    isPortfolioCreation: boolean = false,
    isCurrentParentPortfolio: boolean = false,
    portfolioId?: string
  ) => {
    // Limit the number of references to process

    if (displayLoadingMessages) {
      refNums.forEach((number) => {
        addReferenceLoadingMessage(number, projectId, projectName, "processing");
      });
    }

    refNums = refNums.slice(0, 100);

    const chunkSize = 10;
    const chunks = await chunkArray(refNums, chunkSize);
    let successfulNumbersToReturn: string[] = [];
    let unsuccessfulNumbersToReturn: string[] = [];
    const processChunk = async (chunk: string[]) => {
      const response = await addReferencesToProject(
        projectId,
        chunk,
        false, // isCitation
        isPortfolioCreation,
        isCurrentParentPortfolio,
        portfolioId
      );

      const idToNumberMap = new Map<string, string>(
        response.data.map((doc: { reference_id: string; reference_number: string }) => [
          doc.reference_id,
          doc.reference_number,
        ])
      );

      const successfulNumbers = new Set(
        response.data.map((doc: any) => doc.reference_number)
      );

      successfulNumbersToReturn = [
        ...successfulNumbersToReturn,
        ...Array.from(successfulNumbers as Set<string>),
      ];
      const unsuccessfulNumbers = chunk.filter(
        (number) => !successfulNumbers.has(number)
      );
      unsuccessfulNumbersToReturn = [
        ...unsuccessfulNumbersToReturn,
        ...unsuccessfulNumbers,
      ];

      if (displayLoadingMessages) {
        unsuccessfulNumbers.forEach((number: string) => {
          updateReferenceLoadingErrorMessage(
            number,
            "Invalid reference number",
            projectId
          );
        });
      }

      const referenceIds = response.data.map(
        (ref: { reference_id: string }) => ref.reference_id
      );

      const skipInvalidity = !isCheckboxChecked;
      const uploadResponse = await uploadToVDB(
        projectId,
        referenceIds,
        skipInvalidity,
        true
      );

      successfulNumbersToReturn = [
        ...successfulNumbersToReturn,
        ...uploadResponse.data.successful_ids.map((id: string) =>
          idToNumberMap.get(id)
        ),
      ];
      unsuccessfulNumbersToReturn = [
        ...unsuccessfulNumbersToReturn,
        ...uploadResponse.data.failed_ids.map((id: string) => idToNumberMap.get(id)),
      ];

      if (displayLoadingMessages) {
        uploadResponse.data.successful_ids.forEach((id: string) => {
          updateReferenceLoadingStatus(idToNumberMap.get(id), "success", projectId);
        });

        uploadResponse.data.failed_ids.forEach((id: string) => {
          updateReferenceLoadingErrorMessage(
            idToNumberMap.get(id),
            "Failed to process reference",
            projectId
          );
        });
      }
    };

    const chunkPromises = chunks.map((chunk) =>
      processChunk(chunk).catch((error) => {
        console.error("An error occurred during processing the chunk:", error);
      })
    );

    await Promise.all(chunkPromises);

    if (projectId === currentProjectId) {
      await fetchProjectData(projectId);
    }

    return {
      data: {
        successfulNumbers: successfulNumbersToReturn,
        unsuccessfulNumbers: unsuccessfulNumbersToReturn,
      },
    };
  };

  // Handle adding files to project
  const addAndProcessFiles = async (
    projectId: string,
    projectName: string,
    files: File[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    parentType: ParentType,
    portfolioId?: string
  ) => {
    let unsuccessfulFilesToReturn: { name: string; error: string }[] = [];

    if (displayLoadingMessages) {
      files.forEach((file) => {
        addReferenceLoadingMessage(file.name, projectId, projectName, "uploading");
      });
    }

    const filePromises = files.map((file) => ({
      file,
      promise: addPDFToProject(projectId, file, parentType, portfolioId),
    }));

    const idToNameMap: Record<string, string> = {};
    const results = await Promise.all(filePromises.map(({ promise }) => promise));

    filePromises.forEach(({ file }, index) => {
      if (results[index].success && results[index].data.length > 0) {
        const referenceData = results[index].data[0];
        // Update status to processing

        idToNameMap[referenceData.reference_id] = file.name;

        updateReferenceLoadingStatus(
          file.name,
          "processing",
          parentType == ParentType.PORT ? portfolioId : projectId
        );
      } else {
        // Update status to error

        let errorMessage = "Error uploading file";
        if (results[index].status === 409) {
          errorMessage = "Reference already exists in the project";
        }

        updateReferenceLoadingErrorMessage(
          file.name,
          errorMessage,
          parentType == ParentType.PORT ? portfolioId : projectId
        );

        unsuccessfulFilesToReturn.push({
          name: file.name,
          error: errorMessage,
        });
      }
    });

    const validResults = results.filter(
      (result) => result.success && result.data && result.data.length > 0
    );

    if (validResults.length === 0) {
      console.error("No valid reference IDs obtained from addPDFToProject.");
      // Handle this case appropriately
      return {
        data: {
          unsuccessfulFiles: unsuccessfulFilesToReturn,
        },
      };
    }

    const filesReferenceIds: string[] = validResults.map(
      (result) => result.data[0].reference_id
    );

    const skipInvalidity = isCheckboxChecked ? false : true;
    const uploadResponse = await uploadToVDB(
      projectId,
      filesReferenceIds,
      skipInvalidity,
      true
    );
    const successfulIds = uploadResponse.data.successful_ids;
    const failedIds = uploadResponse.data.failed_ids;

    unsuccessfulFilesToReturn = [
      ...unsuccessfulFilesToReturn,
      ...failedIds.map((id) => ({
        name: idToNameMap[id],
        error: "Error processing file",
      })),
    ];

    if (displayLoadingMessages) {
      successfulIds.forEach((id) => {
        updateReferenceLoadingStatus(idToNameMap[id], "success", projectId);
      });
      failedIds.forEach((id) => {
        updateReferenceLoadingStatus(idToNameMap[id], "error", projectId);
      });
    }

    if (projectId === currentProjectId) {
      await fetchProjectData(projectId);
    }

    return {
      data: {
        unsuccessfulFiles: unsuccessfulFilesToReturn,
      },
    };
  };

  return {
    addAndProcessReferences,
    addAndProcessFiles,
  };
};

export default useProcessReferences;
