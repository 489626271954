/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { splitId } from "@/utils/projectUtils";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";

/**
 * @description Source number button component
 * @param {string} sourceNumber - The source number
 */
interface SourceNumberButtonProps {
  sourceNumber: string;
  prefix?: string;
  link?: string;
}

const SourceNumberButton: React.FC<SourceNumberButtonProps> = ({
  sourceNumber,
  link = "https://patents.google.com/patent/",
  prefix = "",
}) => {
  // Return null if sourceNumber is not provided or exceeds 20 characters
  if (!sourceNumber || sourceNumber.length > 20) {
    return null;
  }

  // Check if sourceNumber already includes the prefix
  let finalString = sourceNumber;

  if (!sourceNumber.startsWith(link)) {
    if (!sourceNumber.startsWith(prefix)) {
      finalString = link + prefix + sourceNumber;
    } else {
      finalString = link + sourceNumber;
    }
  }

  return (
    <a
      href={finalString}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div className="flex items-center gap-1 whitespace-nowrap">
        {splitId(sourceNumber)}
        <OpenInNewWindowIcon className="w-3 h-3" />
      </div>
    </a>
  );
};

export default SourceNumberButton;
